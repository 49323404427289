import React from "react";
import "./header.scss";

export default class Header extends React.Component<any, any> {
    render() {
        let me = this;
        return <div className={"header"}>
            <h1>Электронное тайное голосование<br/>Партии «ЕДИНАЯ РОССИЯ»<br/><span onClick={() => { me.props.signOut(20);}} className={"red"}>КОНФЕРЕНЦИИ РО</span></h1>
        </div>;
    }
}