import React from "react";
import "./vote-checkbox.scss";

interface CheckboxProps {
  checked: boolean
}

export default class Checkbox extends React.Component<any, any> {
  render() {
    let me = this;
    return <div className={"vote-checkbox"}>
        <i id="candidate-{{participant.id}}-chosen" className={`${me.props.checked ? 'checked' : "not-checked"} far fa-check`}></i>
      </div>
  }
}