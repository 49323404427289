import React from "react";
import Bulletin from "../../models/Bulletin";
import Api from "../../gateways/Api";
import Checkbox from "../input/Checkbox";
import BulletinVariant from "../../models/BulletinVariant";
import Button from "../buttons/Button";
import {COLORING_TYPE} from "../../enums/coloring/COLORING_TYPE";
import "./bulletin-view.scss";

interface BulletinProps {
  api: Api,
  bulletin: Bulletin,
  variantsLoaded: () => void,
  afterVoted: (_b: Bulletin) => void,
}

interface BulletinState {
  loadingVariants: boolean;
  variants: any[];
  chosen: BulletinVariant[];
  uploadingVote: boolean;
  voteStatus: null | string;
  verifiedText: null | string;
  errorText: null | string;
  voteUploadStages: string[];
  confirmationCode: boolean;
}

const voteUploadStages = ["encrypting", "signing", "sending", "verified"];

export default class BulletinView extends React.Component<BulletinProps, BulletinState> {

  constructor(_props: BulletinProps) {
    super(_props);
    this.state = {
      loadingVariants: true,
      variants: [],
      chosen: [],
      uploadingVote: false,
      voteStatus: null,
      verifiedText: null,
      errorText: null,
      voteUploadStages: [],
      confirmationCode: false
    };

    this.trigger = this.trigger.bind(this);
    this.sendParsel = this.sendParsel.bind(this);

    this.closeMe = this.closeMe.bind(this);
    this.encryptVote = this.encryptVote.bind(this);
    this.signVote = this.signVote.bind(this);
    this.approveChoosing = this.approveChoosing.bind(this);
  }

  componentDidMount() {
    let me = this;
    let url = `/votings/${this.props.bulletin.voting.id}/show.json`;
    this.props.api.getUrl(url, {}).then((e) => {
      let prevState = {...me.state};
      prevState.variants = e.map((_bV: any) => {return new BulletinVariant(_bV);});
      prevState.loadingVariants = false;
      me.setState(prevState, () => {
        me.props.variantsLoaded();
      });
    });
  }

  trigger(_bV: BulletinVariant) {
    let prevState = {...this.state};
    let index = prevState.chosen.indexOf(_bV);
    if (index > -1) {
      if (!this.props.bulletin.voting.isRating) return;
      prevState.chosen.splice(index, 1);
    } else {
      if (!this.props.bulletin.voting.isRating) {
        prevState.chosen = [];
      }
      prevState.chosen.push(_bV);
    }
    this.setState(prevState);
  }

  sendParsel() {
    let me = this;
    let prevState = {...me.state};
    let content =  me.state.chosen.map((x) => {return x.id;});
    prevState.uploadingVote = true;
    me.setState(prevState, () => {
      me.encryptVote(JSON.stringify(content));
    });
  }

  encrypt(_stringToEncrypt:string, key:string) {
    return _stringToEncrypt;
  }

  encryptVote(_votedPart:string) {
    let me = this;
    let prevState = {...me.state};
    let _encryptedVote = me.encrypt(_votedPart, "");
    prevState.voteUploadStages.push("encrypting");
    me.setState(prevState, () => {
      setTimeout(() => {
        me.signVote(_encryptedVote);
      }, 1000);
    });
  }

  signVote(_encryptedVote:string) {
    let me = this;
    let prevState = {...me.state};
    prevState.voteUploadStages.push("signing");
    if (!me.props.api.userSession.currentEntity) return;
    let vote = {
      content: _encryptedVote,
      user_id: me.props.api.userSession.currentEntity.id
    };
    // alert(JSON.stringify(parsel));
    me.setState(prevState, () => {
      setTimeout(() => {
        me.sendVote(vote);
      }, 1000);
    });
  }

  sendVote(_vote: any) {
    let me = this;
    let prevState = {...me.state};
    prevState.voteUploadStages.push("sending");
    me.props.api.getUrl(`/votings/${me.props.bulletin.voting.id}/upload_vote`, _vote).then((res) => {
      let prevState = {...me.state};
      prevState.voteUploadStages.push("verified");
      prevState.verifiedText = res.status;
      me.setState(prevState);
    }, () => {
      setTimeout(() => {
        me.sendVote(_vote);
      }, 2000);
    });
    //     prevState.voteUploadStages.push("verified");
    //     prevState.verifiedText = "Голос принят";
    //     me.setState(prevState);
    //   }, 2000);
    // });
  }

  approveChoosing() {
    let prevState = {...this.state};
    prevState.confirmationCode = true;
    this.setState(prevState);
  }

  render() {
    let me = this;
    return (
      <div className={"card"} style={{boxShadow: "none", transition: "400ms", opacity: me.state.loadingVariants ? 0 : 1, background: "var(--gray_100)"}} >

        {!me.state.uploadingVote &&
            <h5 style={{
              transition: "400ms",
              lineHeight: "0.98em",
              whiteSpace: "pre-wrap"
            }}><b style={{fontWeight: 900, color: "var(--black)"}}>Голосование по вопросу
                повестки:</b><br/>{me.props.bulletin.voting.title}</h5>
        }
        { !me.state.confirmationCode && !me.state.uploadingVote &&
            <div className={"flex flex-row justify-center w-100"}>
                <div className={"mt-1 w-100 flex flex-column items-start content-start"}>
                  {me.state.variants.map((_variant, _index) => {
                    return <div key={`${JSON.stringify(me.state.chosen)}-${_index}`} onClick={() => {
                      me.trigger(_variant)
                    }} className={"card flex flex-row justify-between items-center content-center  w-100 mb-1"}>
                      <div className={"flex flex-column w-70"}>
                        <p className={_variant.about ? "mb-1" : ""} style={{color: "#000", fontWeight: 900}}>{_variant.title}</p>
                        {_variant.about && <p className={"w-90"} style={{color: "#000"}}>{_variant.about}</p> }
                      </div>
                      <Checkbox checked={me.state.chosen.indexOf(_variant) > -1}/>
                    </div>
                  })
                  }
                </div>
            </div>
        }
        { !me.state.confirmationCode && !me.state.uploadingVote && me.state.chosen.length > 0 &&
          <div className={"w-100 flex flex-row justify-center mb-4 "}>
            <Button onClick={(e) => {me.approveChoosing();}} className={"back-button"}
                    data={{action: {}, caption: "ПОДТВЕРДИТЬ ВЫБОР", button_type: COLORING_TYPE.primary, show_arrow: false}}/>
          </div>
        }
        { me.state.confirmationCode && !me.state.uploadingVote &&
          <div className={"w-100 flex flex-column mb-4 "}>
              <p className={"mt-1 mb-1"} style={{color: "var(--black)", fontSize: "var(--font-size-4)"}}><b>Я, делегат конференции: «{ localStorage.getItem("conference_title") }» подтверждаю свой выбор:</b></p>
              <ul style={{"listStyle": "none"}}>
                {me.state.chosen.map((x) => {
                  return <li className={"mt-1"} style={{color: "var(--color-primary)"}}><b>{x.title}</b></li>
                })}
              </ul>
              <br/>
              <Button onClick={(e) => {me.sendParsel();}} className={"back-button mt-2"}
                      data={{action: {}, caption: "ЗАШИФРОВАТЬ И ОТПРАВИТЬ БЮЛЛЕТЕНЬ", button_type: COLORING_TYPE.primary, show_arrow: false}}/>
          </div>
        }
        { !!me.state.uploadingVote &&
            <div className={"flex flex-row justify-center"}>
                <ul className={"w-100"}>
                  { me.state.voteUploadStages.indexOf("encrypting") > -1 &&
                      <li id="encrypting" className={"bulletin-status mb-2"}>
                          <i className={"fad fa-shield fa-3x"}></i>
                          <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Шифрую бюллетень</p>
                      </li>
                  }
                  { me.state.voteUploadStages.indexOf("signing") > -1 &&
                      <li id="signing" className={"bulletin-status mb-2"}>
                          <i className={"fad fa-file-signature fa-3x"}></i>
                          <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Подписываю бюллетень</p>
                      </li>
                  }
                  { me.state.voteUploadStages.indexOf("sending") > -1 &&
                      <li id="sending" className={"bulletin-status mb-2"}>
                          <i className={"fad fa-cloud-upload-alt fa-3x"}></i>
                          <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>Отправляю бюллетень</p>
                      </li>
                  }
                    {/*<li id="vote-status" className={"bulletin-status mb-2"}>*/}
                    {/*    <i className={"fal fa-circle-notch fa-3x fa-spin"}></i>*/}
                    {/*    <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>{me.state.voteStatus}</p>*/}
                    {/*</li>*/}
                  { me.state.voteUploadStages.indexOf("verified") > -1 &&
                      <li id="verified" className={"bulletin-status mb-2"}>
                          <i className={"fad fa-check fa-3x"}></i>
                          <p className={"w-80"} style={{fontSize: "var(--font-size-4)"}}>{me.state.verifiedText}</p>
                      </li>
                  }

                  {me.state.voteUploadStages.indexOf("verified") > -1 &&
                      <div className={"mt-3 mb-5 w-100 flex flex-row justify-center"}>
                          <Button onClick={(e) => {me.closeMe()}} className={"back-button"}
                                  data={{
                                    action: {},
                                    caption: "ЗАВЕРШИТЬ",
                                    button_type: COLORING_TYPE.primary,
                                    show_arrow: false
                                  }}/>
                      </div>
                  }
                  { me.state.voteUploadStages.indexOf("error") > -1 &&
                      <li id="error" className={"bulletin-status mb-2"}>
                          <i className="fad fa-times fa-3x"></i>
                          <p id="err-text" style={{fontSize: "var(--font-size-4)"}}>{me.state.errorText}</p>
                      </li>
                  }

                </ul>

            </div>
        }
      </div>
    );
  }

  closeMe() {
    this.props.afterVoted(this.props.bulletin);
  }
}