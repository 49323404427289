import React from "react";
import MenuInterface from "./MenuComponentInterface";
import MenuItem from "./menu_items/MenuItemComponent";
import MenuItemComponent from "./menu_items/MenuItemComponent";
import Api from "../../gateways/Api";
import {MenuModelInterface} from "./MenuModelInterface";
import MenuItemComponentInterface from "./menu_items/MenuItemComponentInterface";
import "./styles/menu.scss";
import { Style } from 'react-style-tag';
import {COLORING_TYPE} from "../../enums/coloring/COLORING_TYPE";
import Button from "../buttons/Button";


interface MenuPropsInterface {
    getFromUrl?: string,
    rawData?: MenuModelInterface,
    api: Api,
    isInside?: boolean
}

interface MenuStateInterface extends MenuInterface {
    showingMenu: string | null
}

export default class MenuComponent extends React.Component<MenuPropsInterface, MenuStateInterface> {
    menuItemRefs: React.Ref<MenuItem>[];

    static convertJSONtoMenuModelInterface(_inputJSON: any):MenuModelInterface {
        let res = {..._inputJSON};
        res.menuItems = res.menu_items.map((e: any) => {
            return e as MenuItemComponentInterface
        })
        return res;
    }

    static convertJSONtoMenuComponentStateInterface(_inputJSON: any):MenuStateInterface {
        let res = {..._inputJSON};
        res.menuItems = res.menu_items.map((e: any) => {
            return e as MenuItemComponentInterface
        })
        return res;
    }

    constructor(_props: MenuPropsInterface) {
        super(_props);
        this.menuItemRefs = [];
        this.state = {
            hide_back_button: false,
            menuItems: [],
            title: "",
            showingMenu: null
        };
        this.generateState = this.generateState.bind(this);
        this.callback = this.callback.bind(this);
        this.back = this.back.bind(this);
    }

    back() {
        let prevState = {...this.state};
        prevState.showingMenu = null;
        this.setState(prevState);
    }

    generateState(_props:MenuPropsInterface):void {
        let me = this;
        if (this.props.rawData) {
            this.setState(MenuComponent.convertJSONtoMenuComponentStateInterface(this.props.rawData));
            return;
        }
        if (this.props.getFromUrl) {
            this.props.api.getUrl(this.props.getFromUrl, {}).then((e: any) => {
                if (e?.conference_title && !localStorage.getItem("conference_title")) {
                    localStorage.setItem("conference_title", e?.conference_title);
                }
                me.setState(MenuComponent.convertJSONtoMenuComponentStateInterface(e));
            });
        }
    }

    componentDidMount() {
        this.generateState(this.props);
    }

    callback(_e: any) {
        let me = this;
        // alert(JSON.stringify(_e));
        if (_e.get_menu) {
            let prevState = {...me.state};
            prevState.showingMenu = _e.get_menu;
            me.setState(prevState);
        }
    }


    render() {
        let me = this;
        return <div className={`${me.props.isInside ? 'menu-inner' : "menu"} relative`}>
            {me.state.custom_css &&
              <Style>{me.state.custom_css}</Style>
            }
            {
              !me.state.showingMenu &&
              <div className={"flex flex-column"}>
                  {me.state.menuItems.map((_menuItem: MenuItemComponentInterface, _index: number) => {
                      return <MenuItemComponent
                        data={_menuItem}
                        // ref={me.menuItemRefs[i]}
                        amountOfItems={me.state.menuItems.length}
                        index={_index}
                        showIndex={me.state.showIndex}
                        // amount_of_items={me.state.menuItems.length}
                        // handleInteraction={me.handleInteraction}
                        // onDidMountCallBack={me.callback}
                        // data={menuItem}
                        callback={me.callback}
                        key={`menu-item-${_index}`}
                      />
                  })
                  }
              </div>
            }

            {
              me.state.showingMenu &&
              <div className={""}>
                  {!me.props.isInside &&
                    <Button onClick={me.back} className={"back-button"}
                            data={{action: {}, caption: "Назад", button_type: COLORING_TYPE.back}}/>
                  }
                  <MenuComponent isInside={true} getFromUrl={me.state.showingMenu}
                                 api={me.props.api} />
              </div>
            }
        </div>
    }
}