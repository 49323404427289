import JWTContainer from "./JWTContainer";

export default class FetchRequest {
  url: URL;
  data: any;
  anonymous: boolean;
  amountOfTries: number;
  madeResolve?: (value: (PromiseLike<unknown> | unknown)) => void;
  madeReject?: (value: (PromiseLike<unknown> | unknown)) => void;
  jwtContainer?: JWTContainer | null;

  constructor(_url: URL, _data: any, _anonymous: boolean, _jwtContainer: JWTContainer | null | undefined) {
    this.url = _url;
    this.data = _data;
    this.anonymous = _anonymous;
    this.amountOfTries = 0;
    this.jwtContainer = _jwtContainer;

    this.generateHeadersWithAuthorization = this.generateHeadersWithAuthorization.bind(this);
  }

  generateHeaders():HeadersInit {
    if (this.anonymous) {
      return this.generateAnonymousHeaders();
    }
    return this.generateHeadersWithAuthorization();
  }

  generateContentTypetData() {
    let result = "application/json";
    if (this.data.body && this.data.body.constructor === FormData) {
      result = "multipart/form-data";
      return null;
    }
    return result;
  }
  generateAnonymousHeaders() {
    let headers = {
      'Accept': 'application/json',
      "mode": 'cors',
    } as any;
    let contentType = this.generateContentTypetData();
    if (contentType) {
      headers['Content-Type'] = this.generateContentTypetData();
    }
    return headers;
  }

  generateHeadersWithAuthorization() {
    if (!this.jwtContainer) throw new Error('Запрос без активного JWT');
    let headers = {
      'Accept': 'application/json',
      "mode": 'cors',
      "Authorization": this.jwtContainer.content,
      "csrf": this.jwtContainer.csrf
    } as any;
    let contentType = this.generateContentTypetData();
    if (contentType) {
      headers['Content-Type'] = this.generateContentTypetData();
    }
    return headers;
  }


  perform():Promise<any> {
    let me = this;
    return new Promise((resolve, reject) => {
      me.amountOfTries += 1;
      let data = {...me.data};
      data.headers = this.generateHeaders();
      return fetch(this.url, data).then((e: Response) => {
        return e.json()
      }).then((_res) => {
        resolve(_res);
      }).catch((e) => {
        me.amountOfTries += 1;
        reject(e);
      });
    });
  }

  async make():Promise<any> {
    let me = this;
    let promise = new Promise((_resolve, _reject) => {
      me.madeResolve = _resolve;
      me.madeReject = _reject;
    });
    return promise;
  }
}