import Voting from "./Voting";

export default class Bulletin {
  static getBulletinsFromLocalStorage(): Bulletin[] {
    let bulletinsInMemory = localStorage.getItem("active_bulletins");
    if (bulletinsInMemory) {
      let bulletins = JSON.parse(bulletinsInMemory);
      return bulletins.map((x:any) => {return new Bulletin(x);})
    }
    return [];
  }

  id: number;
  publicKey: string;
  privateKey: string;
  voting: Voting;

  constructor(_data: any) {
    this.id = _data.id;
    this.publicKey = _data.publicKey;
    this.privateKey = _data.privateKey;
    this.voting = new Voting(_data.voting);
  }
}