import React from 'react';
import './style/global_style.scss';
import Login from "./components/login/Login";
import Header from "./components/header/Header";
import Api from "./gateways/Api";
import {Panel} from "./components/content_containers/panels/Panel";
import logo from "./components/logo/logo.svg";

interface AppProps {
  api: Api
}

interface AppState {
  showLogin: boolean,
  showContent: boolean,
  conferenceId: string | number | null,
  signoutCount: number
}

export default class App extends React.Component<any, AppState> {
  private api: any;
  private loaded: boolean;

  constructor(_props: any) {
    super(_props);

    this.state = {
      showLogin: !localStorage.getItem("current_conference_id"),
      conferenceId: localStorage.getItem("current_conference_id"),
      showContent: !!localStorage.getItem("current_conference_id"),
      signoutCount: 0
    }

    this.api = this.props.api;
    this.loaded = false;

    this.goToContent = this.goToContent.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  componentDidMount() {
    this.loaded = true;
  }

  goToContent(_conferenceData: any) {
    let prevState = {...this.state};
    prevState.showLogin = false;
    prevState.showContent = true;
    prevState.conferenceId = _conferenceData.id;
    localStorage.setItem("current_conference_id", _conferenceData.id);
    document.location.reload();
    this.setState(prevState);
  }

  signOut(_limit = 10) {
    let me = this;
    let prevState = {...this.state};
    prevState.signoutCount += 1;
    console.log(prevState.signoutCount);
    me.setState(prevState, () => {
      if (me.state.signoutCount > _limit) {
        localStorage.removeItem("current_conference_id");
        localStorage.removeItem("my_jwt");
        localStorage.removeItem("shown_voting");
        localStorage.removeItem("shown_votings");
        localStorage.removeItem("conference_title");
        document.location.reload();
      }
    });
    // let confirm = window.confirm("Вы уверены?");
    // if (!confirm) return;
  }


  render() {
    let me = this;

    return <>
      <Header signOut={me.signOut} />
      { me.state.showLogin && (me.state.conferenceId === null) &&
        <Login api={me.api}
               callback={me.goToContent}/>
      }
      { me.state.showContent && me.state.conferenceId &&
        <Panel api={me.api}
               conferenceId={me.state.conferenceId} />
      }
      { me.state.showContent &&
        <div className={"w-100 flex flex-row justify-center mt-3"}>
            <img onClick={() => { me.signOut(10); }}
                 style={{width: "80px", marginLeft: "auto", marginRight: "auto"}}
                 src={logo}/>
            {/*<img style={{width: "80px", marginLeft: "auto", marginRight: "auto"}} src={logo}/>*/}
        </div>
      }
    </>;
  }
}