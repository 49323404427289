import React from "react";
import "./modal-dialog-style.scss";

interface ModalDialogProps {
  closeOnBackdropClick?: boolean
  isOpen?: boolean,
  onClose?: () => void,
  children: JSX.Element | JSX.Element[],
  style: any,
  closeBtn?: boolean
}

export default class ModalDialog extends React.Component<ModalDialogProps, any> {
  constructor(_props: ModalDialogProps) {
    super(_props);
    this.state = {
      isOpen: !!this.props.isOpen
    }

    this.close = this.close.bind(this);
  }

  close() {
    let me = this;
    if (!me.props.closeOnBackdropClick) return;
    let prevState = {...this.state};
    prevState.isOpen = false;
    this.setState(prevState, () => {
      if(me.props.onClose) me.props.onClose();
    });
  }

  render() {
    let me = this;
    return <div onClick={me.close}
                style={ me.state.isOpen ? ( me.props.style ? me.props.style : {} ) : {display: "none"} }
                className={"modal-dialog-background"}>

      { me.state.isOpen &&
          <div style={{pointerEvents: "all", maxHeight: "90vh", overflow: "scroll", position: "relative"}}
               className={"card"}
          onClick={(_e) => {_e.stopPropagation();}}>
            {
              !me.props.closeOnBackdropClick &&
                <i onClick={me.close}
                   style={{
                     cursor: "pointer",
                     position: "absolute",
                     filter: "invert(1)",
                     top: "10px",
                     left: "10px"
                   }}
                   className={"fal fa-times"}></i>
            }

            {me.props.closeBtn && ( // Conditionally render close button
              <i
                onClick={me.close}
                style={{
                  cursor: "pointer",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  filter: "invert(1)",
                  zIndex: 1000 // Ensuring it's above other elements
                }}
                className={"fal fa-times"}
              ></i>
            )}

            {me.props.children}
          </div>
      }
    </div>;
  }
}