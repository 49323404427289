export default class Voting {
  title: string;
  id: number;
  voting_public_key: string;
  isRating: boolean;

  constructor(_data: any) {
    this.title = _data.title;
    this.voting_public_key = _data.voting_public_key;
    this.id = _data.id;
    this.isRating = _data.is_rating;
  }
}