import UserSession from "../session/UserSession";
import FetchRequest from "./FetchRequest";

export default class Api {
    get userSession(): UserSession {
        return this._userSession;
    }
    serverUrl: string;
    private readonly _userSession: UserSession;

    constructor(_serverUrl: string, _userSession: UserSession) {
        this.serverUrl = _serverUrl;
        this._userSession = _userSession;
    }

    getUrl(_url: string, _fetchParams:any):Promise<any> {
        let me = this;
        let url = new URL(`${this.serverUrl}/${_url}`);
        if (_url.indexOf("http:") > -1 || _url.indexOf("https://") > -1) {
            url = new URL(`${_url}`);
        }
        let anonymous = !this._userSession.currentEntity;
        let jwtContainer = this._userSession.currentEntity ? this._userSession.currentEntity.jwtContainer : null;
        let data = {};
        if (_fetchParams) {
           data = {method: "post", body: JSON.stringify(_fetchParams)};
        }
        let fetchRequest = new FetchRequest(url, data, anonymous, jwtContainer);

        return new Promise((_resolve, _reject) => {
            fetchRequest.perform().then((_e) => {_resolve(_e);}, (_err) => {_reject(_err);})
        })
    }
}