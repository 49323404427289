import User from "./User";

export default class UserSession {
  get jwtToken(): string | null {
    return this._jwtToken;
  }

  set jwtToken(value: string | null) {
    this._jwtToken = value;
    if (value) {
      this.currentEntity = new User(value);
      localStorage.setItem("my_jwt", value);
    }
  }
  private _jwtToken: string | null;
  currentEntity: User | null;

  constructor(_jwtToken: string | null) {
    this.currentEntity = null;
    this._jwtToken = null;

    if (_jwtToken) {
      this.jwtToken = _jwtToken;

    }
  }

}