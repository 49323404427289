import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import Api from "./gateways/Api";
import UserSession from "./session/UserSession";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

// let api = new Api("https://vote-conf.skobeltsyn.com");
let userSession = new UserSession(localStorage.getItem("my_jwt"));
// let api = new Api("http://localhost:3000", userSession);
// let api = new Api("https://event.epg4.space", userSession);
// let api = new Api("https://event.er-etg.tech", userSession);
let api = new Api("https://stage.er-etg.tech", userSession);

root.render(
  <React.StrictMode>
    <App api={api} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
