import React from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import "./accordion.scss";
import MenuItemComponent from "../../menus/menu_items/MenuItemComponent";
import MenuItemComponentInterface from "../../menus/menu_items/MenuItemComponentInterface";
import {AccordionComponentProps} from "./AccorionComponentsProps";

export default class AccordionContainer extends React.Component<AccordionComponentProps, any> {
  private typeName: string;

  constructor(_props: AccordionComponentProps) {
    super(_props);
    this.typeName = "Accordion";
    this.state = {
      menu: {
        menu_items: []
      }
    }
    // if (!this.props.data.menu_items) return;
    // this.state.menu.menu_items = this.props.data.menu_items.map((x, myIndex) => {
    //   x.ref = React.createRef();
    //   x.interactionCounter = 0;
    //   x.myIndex = myIndex;
    //   return x;
    // });
  }

  componentDidMount() {
    let me = this;
    // let prevState = {... me.this};
    // if (!me.props.data.menu_items) return;
    // prevState.menu.menu_items = me.props.data.menu_items.map((x, myIndex) => {
    //   x.ref = React.createRef();
    //   x.interactionCounter = 0;
    //   x.myIndex = myIndex;
    //   return x;
    // });
    // me.setState(prevState);

  }

  static pad(num:number, size:number):string {
    let numAsString = num.toString();
    while (numAsString.length < size) numAsString = "0" + numAsString;
    return numAsString;
  }

  render() {
    let me = this;
    // return <p>Аккордеон</p>
    return (
      <Accordion allowZeroExpanded={true}>
         <AccordionItem>
           <AccordionItemHeading>
             <AccordionItemButton>
               <div className={`accordion-main-trigger ${me.props.index === 0 ? 'round-up' : ''} ${me.props.index === (me.props.amountOfItems - 1) ? 'round-down' : ''}`} >
                 <div className={"number"}>{AccordionContainer.pad(me.props.index , 2)}</div>
                 <div className={"accordeon-title"} dangerouslySetInnerHTML={{__html: me.props.data.title}}></div>
               </div>
             </AccordionItemButton>
           </AccordionItemHeading>
           <AccordionItemPanel>
             <p style={{color: "var(--black", whiteSpace: "pre-wrap"}}>
               {me.props.data.content}
             </p>
             { me.props.data.menuItems.map((menuItem: MenuItemComponentInterface, i: number) => {
               return <MenuItemComponent showIndex={me.props.showIndex} index={i} amountOfItems={me.state.menu.menu_items.length}  data={menuItem} key={`menu-item-${i}`}/>
             })}
           </AccordionItemPanel>
         </AccordionItem>
      </Accordion>
    );
  }
}