import JWTContainer from "../gateways/JWTContainer";

export default class User {
  id?: number;
  lastName?: string;
  firstName?: string;
  middleName?: string;
  jwtContainer?: JWTContainer;

  constructor(_jwtToken: string | null) {
    if (_jwtToken) {
      this.jwtContainer = new JWTContainer(_jwtToken, "no-csrf");
      let _props = this.parseJwt(_jwtToken);
      this.id = _props.id;
      this.lastName = _props.last_name;
      this.firstName = _props.first_name;
      this.middleName = _props.middle_name;
    }
  }

  parseJwt(token: string) {
    let base64Url = token.split('.')[1];
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    let jsonPayload = decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  name() {
    return `${this.firstName} ${this.middleName}`;
  }
}