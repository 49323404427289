import React from "react";
import Bulletin from "../../models/Bulletin";
import Api from "../../gateways/Api";
import BulletinView from "./BulletinView";
import FullscreenLoader from "../loaders/fullscreen/FullscreenLoader";
import CircleSpinner from "../loaders/spinners/CircleSpinner";
import "./voting-part-view.scss";
import {QrReader} from "react-qr-reader";


interface VotingPartProps {
  acceptingBulletinsUrl: string;
  api: Api;
  close: (_votingId: string) => void,
  votingId: string;
  style: any;
  useQR: boolean;
}

interface VotingPartState {
  loading: boolean;
  loadingText: JSX.Element;
  bulletins: Bulletin[];
  activeBulletin: Bulletin | null;
  scannedQrId: string | null;
  error: string | null;
}

export default class VotingPart extends React.Component<VotingPartProps, VotingPartState> {
  private loaded: boolean;

  constructor(_props: VotingPartProps) {
    super(_props);
    this.state = {
      loading: true,
      loadingText: <h3>Загрузка</h3>,
      bulletins: [],
      activeBulletin: null,
      scannedQrId: this.props.useQR ? null : "na",
      error: null
    };

    this.variantsLoaded = this.variantsLoaded.bind(this);
    this.afterVoted = this.afterVoted.bind(this);
    this.initiateVoting = this.initiateVoting.bind(this);
    this.qrPrecheck = this.qrPrecheck.bind(this);

    this.loaded = false;
  }

  variantsLoaded() {
    let prevState = {...this.state};
    prevState.loading = false;
    this.setState(prevState);
    this.setActiveBulletin = this.setActiveBulletin.bind(this);
  }

  componentDidMount() {
    if (this.loaded) return;

    this.loaded = true;

    let me = this;
    if (!me.props.useQR) {
      me.initiateVoting();
    }

  }

  initiateVoting() {
    let me = this;

    me.props.api.getUrl(me.props.acceptingBulletinsUrl, {}).then((e) => {
      // alert(JSON.stringify(e));
      let prevState = {...this.state};
      prevState.bulletins = e.map((_x: any) => {
        return new Bulletin(_x);
      });
      this.setState(prevState, () => {
        if (me.state.bulletins.length > 0) {
          setTimeout(() => {
            me.setActiveBulletin(me.state.bulletins[0]);
          }, 2000);
        }
      });
    });
  }

  setActiveBulletin(_bulletin: Bulletin) {
    let prevState = {...this.state};
    prevState.activeBulletin = _bulletin;
    this.setState(prevState);
  }

  afterVoted(_bulletin: Bulletin) {
    let me = this;
    let bulletinIndex = me.state.bulletins.indexOf(_bulletin);
    let prevState = {...me.state};
    prevState.bulletins.splice(bulletinIndex, 1);
    if (prevState.bulletins.length > 0) {
      prevState.activeBulletin = prevState.bulletins[0];
      me.setState(prevState);
      return;
    } else {
      prevState.activeBulletin = null;
    }

    me.setState(prevState, () => {
      // me.props.close(me.props.votingId);
      me.props.close(`${_bulletin.voting.id}`);
    });
  }

  qrPrecheck(_qrId: string) {
    let me = this;
    let url = `/votings/${me.props.votingId}/check_qr_before_start`;

    try {
      me.props.api.getUrl(url, {
        value: JSON.parse(_qrId)["value"]
      }).then((_res) => {
        console.log(_res);
        if (_res.success) {
          me.setState({
            scannedQrId: _res.toString()
          }, () => {
            me.initiateVoting();
          });
        } else {
          me.setState({
            error: _res.error
          })
        }
      });
    } catch (e) {
      me.setState({
        error: "Неверный QR код"
      })
    }
  }


  render() {
    let me = this;
    return <div className={"voting-part-container"}
                style={me.props.style}
                >
      { me.state.scannedQrId &&
          <div key={`${JSON.stringify(me.state.activeBulletin)}`}>
            {
              // me.state.bulletins.map((_b: Bulletin) => {
              me.state.activeBulletin &&
                <BulletinView variantsLoaded={me.variantsLoaded}
                              bulletin={me.state.activeBulletin}
                              api={me.props.api}
                              afterVoted={me.afterVoted}/>
            }
              <FullscreenLoader style={{zIndex: 100, backgroundColor: "var(--primary-color)"}}
                                key={`${me.state.loading ? "1" : "2"}`} greeting={me.state.loadingText}
                                loading={me.state.loading} spinner={<CircleSpinner circleColor={"#19183D"}/>}/>
          </div>
      }
      {
        !me.state.scannedQrId &&
          <div key={`scanner-${me.state.scannedQrId}`}>
            <h3 style={{  marginLeft: "auto",
                          marginRight: "auto",
                          width: "100%",
                          paddingTop: "10px",
                          textAlign: "center"
            }}>Отсканируйте QR код</h3>

            { me.state.error &&
              <p style={{color: "#f00", width: "100%", textAlign: "center"}}>{me.state.error}</p>
            }

            <QrReader
                scanDelay={500}
                containerStyle={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "10px"
                }}
                onResult={( _res: any, _err: any ) => {
                  if (!!_res) {
                    me.setState({
                      error: null
                    }, () => {
                      me.qrPrecheck(_res.toString());
                      });
                    // alert(_res);
                  }

                  // if (!!_err) {
                  //   console.info(_err);
                  // }
                  // console.log(_res);

                }}
                constraints={{ facingMode:'environment'}}
            />
            <span style={{display: "flex", width: "40px", height: "40px"}}
                  onClick={() => {
                    me.setState({
                      scannedQrId: "scannedQrId"
                    })
                  }}
                  ></span>
          </div>
      }
    </div>;
  }
}