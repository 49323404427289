import React from "react";
import Command from "../../models/Command";

interface HtmlViewContainerPropsInterface {
  callback?: (_e:any) => Command,
  data: string
}

interface HtmlViewContainerStateInterface {
  // callback: (_e:any) => Command,
  // data: string
}


export default class HtmlViewContainer extends React.Component<HtmlViewContainerPropsInterface, HtmlViewContainerStateInterface> {
  constructor(_props: HtmlViewContainerPropsInterface) {
    super(_props);
  }

  render() {
    let me = this;
    return <div dangerouslySetInnerHTML={{__html: me.props.data}} />;
  }
}