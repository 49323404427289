import React from "react";
import MenuItemComponentInterface from "./MenuItemComponentInterface";
import HtmlViewContainer from "../../content_containers/HtmlViewContainer";
import {Style} from "react-style-tag";
import AccordionContainer from "../../content_containers/accordion/AccordionContainer";
import {AccordionComponentProps} from "../../content_containers/accordion/AccorionComponentsProps";
import MenuItemInterface from "./MenuItemComponentInterface";
import {AccorionModelInterface} from "../../content_containers/accordion/AccorionModelInterface";
import QrScanContainer from "../../content_containers/qr/QrScanContainer";
import Button from "../../buttons/Button";
import ButtonInterface from "../../buttons/ButtonInterface";
import ModalDialog from "../../modal_dialog/ModalDialog";
import DocumentPDFViewer from "../../document_pdf_viewer/DocumentPDFViewer";

interface MenuItemComponentPropsInterface {
    data: MenuItemComponentInterface,
    index: number,
    showIndex: boolean | undefined,
    amountOfItems: number,
    callback?: (_e:any) => void
}

interface MenuItemComponentStateInterface {
    data: MenuItemComponentInterface,
    showDocumentInModal: string | null,
    showIframeInModal: string | null
}

export default class MenuItemComponent extends React.Component<MenuItemComponentPropsInterface, MenuItemComponentStateInterface> {
    private htmlRef: React.RefObject<HtmlViewContainer>;
    private accordionRef: React.RefObject<AccordionContainer>;
    private buttonRef: React.RefObject<Button>;

    constructor(_props: MenuItemComponentPropsInterface) {
        super(_props);
        this.state = {
            data: this.props.data,
            showDocumentInModal: null,
            showIframeInModal: null
        }
        this.htmlRef = React.createRef<HtmlViewContainer>();
        this.accordionRef = React.createRef<AccordionContainer>();
        this.buttonRef = React.createRef<Button>();

        this.callback = this.callback.bind(this);
        this.closeModalDocument = this.closeModalDocument.bind(this);
    }

    componentDidMount() {
        let me = this;
        window.addEventListener("onmessage", () => {
            me.closeModalDocument();
        }, false);
    }

    componentWillUnmount() {
        let me = this;
        window.removeEventListener("onmessage", () => {
            me.closeModalDocument();
        });
    }

    prepareRawAccordionDataToAccordionComponentProps(_data: any): AccorionModelInterface {
        let res = {..._data};
        res.menuItems = res.menu_items.map((_x: any) => {
            return _x as MenuItemComponentInterface;
        });
        return res as AccorionModelInterface;
    }

    callback(_e: any) {
        if(_e.open_document) {
            let prevState = {...this.state};
            prevState.showDocumentInModal = _e.open_document;
            this.setState(prevState, () => {});
            return;
        }

        if(_e.iframe) {
            let prevState = {...this.state};
            prevState.showIframeInModal = _e.iframe;
            this.setState(prevState, () => {});
            return;
        }

        if (this.props.callback) {
            this.props.callback(_e);
        }

        // alert(JSON.stringify(_e));
    }

    closeModalDocument() {
        let prevState = {...this.state};
        prevState.showDocumentInModal = null;
        prevState.showIframeInModal = null;
        this.setState(prevState);
    }

    render() {
        let me = this;
        return <div>
            {/*<p>1312123{JSON.stringify(me.props.data)}</p>*/}
            {me.props.data.custom_css &&
              <Style>{me.props.data.custom_css}</Style>
            }

            {
              me.props.data.html &&
                <HtmlViewContainer ref={me.htmlRef} data={me.props.data.html.content} />
            }
            {
              me.state.data.button &&
                <Button callback={me.callback} ref={me.buttonRef} data={me.props.data.button as ButtonInterface} />
            }

            {
              me.props.data.accordion &&
                <AccordionContainer showIndex={!!me.props.showIndex} ref={me.accordionRef} amountOfItems={me.props.amountOfItems} index={me.props.index} data={me.prepareRawAccordionDataToAccordionComponentProps(me.props.data.accordion)} />
            }

            {
              me.state.data.qr &&
                <QrScanContainer />
            }

            {
              me.state.showDocumentInModal && me.state.showDocumentInModal.toLowerCase().endsWith(".pdf")  &&
                <ModalDialog style={{minHeight: "90vh"}} onClose={this.closeModalDocument} isOpen={true} closeOnBackdropClick={true} closeBtn={true} >
                    <DocumentPDFViewer hideControls={true}
                                       src={me.state.showDocumentInModal}
                    />
                </ModalDialog>
            }

            {
              me.state.showDocumentInModal && (me.state.showDocumentInModal.toLowerCase().endsWith(".png") || me.state.showDocumentInModal.toLowerCase().endsWith(".jpg") || me.state.showDocumentInModal.toLowerCase().endsWith(".jpeg"))  &&
              <ModalDialog style={{minHeight: "90vh"}} onClose={this.closeModalDocument} isOpen={true} closeOnBackdropClick={true} closeBtn={true} >
                  <img
                    style={{marginLeft: "auto",
                            marginRight: "auto",
                            maxWidth: "100%",
                            objectFit: "contain"}}
                    src={me.state.showDocumentInModal}/>
              </ModalDialog>
            }

            {
              me.state.showDocumentInModal && (me.state.showDocumentInModal.toLowerCase().endsWith(".webm") || me.state.showDocumentInModal.toLowerCase().endsWith(".mp4") || me.state.showDocumentInModal.toLowerCase().endsWith(".mov") )  &&
              <ModalDialog style={{minHeight: "90vh"}}
                           onClose={this.closeModalDocument}
                           isOpen={true}
                           closeOnBackdropClick={true}
                           closeBtn={true}
              >
                  <div style={{
                      display: "block",
                      height: "100%",
                      minWidth: "320px",
                      minHeight: "240px",
                      pointerEvents: "all"}}>
                      <video
                        autoPlay={true}
                        controls={true}
                        style={{
                          width: "100%",
                          maxWidth: "320px",
                          background: "#ededed",
                          pointerEvents: "all"
                        }}
                        src={me.state.showDocumentInModal} />
                  </div>
              </ModalDialog>
            }

            {
              me.state.showIframeInModal &&
              <ModalDialog style={{minHeight: "90vh"}} onClose={this.closeModalDocument} isOpen={true} closeOnBackdropClick={true} >
                  <iframe style={{border: "none", height: "90vh", width: "90vw"}}
                          src={me.state.showIframeInModal} />
              </ModalDialog>
            }


            {/* TODO: компоненты внизу */}
            {/*{*/}
            {/*  me.state.data.map &&*/}
            {/*    <Map ref={me.mapRef} index={me.props.index} callback={me.props.callback} data={me.state.data.map} />*/}
            {/*}*/}

            {/*{*/}
            {/*  me.state.data.field_input &&*/}
            {/*    <FieldInput ref={me.fieldRef} handleInteraction={me.props.handleInteraction} callback={me.props.callback} data={me.state.data.field_input} />*/}
            {/*}*/}

            {/*<div key={JSON.stringify(me.state.data.dialog)}>*/}
            {/*    {*/}
            {/*      me.state.data.dialog &&*/}
            {/*        <MenuItemDialog ref={me.dialogRef}  index={me.props.index}  callback={me.props.callback} data={me.state.data.dialog} />*/}
            {/*    }*/}
            {/*</div>*/}
        </div>
    }
}