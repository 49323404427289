export default class BulletinVariant {
  id: number;
  candidateId: number;
  about: string;
  title: string;

  constructor(_dataAsJson: any) {
    this.id = _dataAsJson.voting_variant_id;
    this.title = _dataAsJson.title;
    this.candidateId = _dataAsJson.candidateId;
    this.about = _dataAsJson.about;
  }

}