import React from "react";
import ButtonInterface from "./ButtonInterface";
import {COLORING_TYPE} from "../../enums/coloring/COLORING_TYPE";
import "./button.scss";

interface ButtonProps {
  data: ButtonInterface,
  className?: string,
  onClick?: (e:any) => void
  callback?: (e:any) => void
}

interface ButtoState {
  // data: ButtonInterface
}

export default class Button extends React.Component<ButtonProps, ButtoState> {
  constructor(_props: ButtonProps) {
    super(_props);
    this.parseAction = this.parseAction.bind(this);
  }

  parseAction() {
    let me = this;
    // alert(JSON.stringify(me.props.data));
    if (me.props.callback) me.props.callback(me.props.data.action);
  }

  render() {
    let me = this;
    let isDisabled = !me.props.data.action || !!me.props.data.disabled;

    return (<button onClick={ (e) => {me.props.onClick ? me.props.onClick(e) : me.parseAction();} } className={`${me.props.className} ${me.props.data.button_type} ${isDisabled ? 'disabled' : ''} ${me.props.data.align_left ? 'align-left' : ''}`}>
      { COLORING_TYPE.back === me.props.data.button_type &&
          <i className={"fal fa-angle-left fa-2x move-left"} />
      }
      { !!me.props.data.fa_icon &&
          <i className={`${me.props.data.fa_icon} move-left`} />
      }
      <p>{me.props.data.caption}</p>
      {me.props.data.show_arrow &&
          <i className={"fal fa-angle-right fa-2x"} />
      }
      {me.props.data.show_rounded_arrow &&
          <div className={"fa-rounded"}>
              <i className={"far fa-angle-right fa-2x"} />
          </div>
      }
    </button>);
  }
}