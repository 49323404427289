import React from "react";
import "../../style/global_style.scss";

interface HiderProps {
  isOpen: boolean;
  showTitle?: JSX.Element | JSX.Element[];
  closeTitle?: JSX.Element | JSX.Element[];
  heading?: JSX.Element | JSX.Element[];
  children: JSX.Element | JSX.Element[];
  headerClassName?: string;
  onChange?: (_res:boolean) => void
}

interface HiderState {
  isOpen: boolean;
  showTitle?: JSX.Element | JSX.Element[];
  closeTitle?: JSX.Element | JSX.Element[];
}

export default class Hider extends React.Component<HiderProps, HiderState> {
  constructor(_props: HiderProps) {
    super(_props);
    this.state = {
      isOpen: this.props.isOpen,
      showTitle: this.props.showTitle,
      closeTitle: this.props.closeTitle
    }
    this.triggerShow = this.triggerShow.bind(this);
  }

  triggerShow() {
    let me = this;
    let prevState = {...this.state};
    prevState.isOpen = !prevState.isOpen;
    this.setState(prevState, () => {
      if (me.props.onChange) {
        me.props.onChange(prevState.isOpen);
      }
    });
  }

  render() {
    let me = this;
    return <>
      <div className={me.props.headerClassName ? me.props.headerClassName : "flex flex-row justify-between w-100"}>
        { me.props.heading }
        <div onClick={me.triggerShow}>
          {me.state.isOpen ? me.state.closeTitle : me.state.showTitle}
        </div>
      </div>
      {me.state.isOpen &&
        <div className={"v-spacer-1"}></div>
      }
      {me.state.isOpen &&
        me.props.children
      }
    </>
  }
}