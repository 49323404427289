import "./documentStyle.scss";
import React, {useState, useRef, useEffect} from 'react';
import { Document, Page, pdfjs  } from 'react-pdf';
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
const magnifierStep = 0.07;
export default function DocumentPDFViewer(props) {
  const fullScreenEl = useRef(null);
  const searchBoxRef = useRef(null);
  const [searchVal, setSearchVal] = useState(null);
  const [needToSearchFirst, setNeedToSearchFirst] = useState(null);
  const [wasRotated, setWasRotated] = useState(false);
  const [numPages, setNumPages] = useState(null);
  const [matches, setMatches] = useState([]);
  const [prevSearchValue, setPrevSearchValue] = useState(null);
  const [currentMatchedIndex, setCurrentMatchedIndex] = useState(0);
  const [fullscreenMode, setFullscreenMode] = useState(false);
  const [pagesPinchZoom, setPagesPinchZoom] = useState({});
  const [pageNumber, setPageNumber] = useState(1);
  const [magnification, setMagnification] = useState(props.magnification ? props.magnification : 1);

  useEffect(() => {
    document.addEventListener('searchUpdate', (e) => {
      // alert(window.currentSearch);
      setNeedToSearchFirst(window.currentSearch);
      // searchBoxRef.current.value = window.currentSearch;
    }, false);
    // searchBoxRef.current.value = "123"; //window.currentSearch;
  });

  useEffect(() => {
    window.addEventListener("keydown", (key) => {
      if (27 === key.keyCode) {
        fullScreen(false);
      }
      setFullscreenMode(false);
    });
  });

  useEffect(() => {
    if (!props.hideControls) {
      let fullscreenEventListenerNames = ["fullscreenchange", "mozfullscreenchange", "webkitfullscreenchange", "msfullscreenchange"];
      for (let fullscreenListenerName of fullscreenEventListenerNames)
        document.addEventListener(fullscreenListenerName, () => {
          if (!document.fullscreenElement) {
            fullScreen(false);
            setFullscreenMode(false);
          }
        });
    }
  });

  useEffect(() => {
    if (props.hideControls) return;
    if (window.innerWidth < 520) {
      window.addEventListener('orientationchange', (event) => {
        console.log("ROTATE");
        if (window.innerHeight > window.innerWidth) {
          console.log("FULLSCREEN");
          fullScreen(true);
          setFullscreenMode(true);
        } else {
          fullScreen(false);
          setFullscreenMode(false);
        }

      });
    }
  });

  function changeMagnification(_value) {
    setMagnification(magnification + _value);
  }

  function checkSearchLength(_value) {
    let val;
    for (const div of document.querySelectorAll('.found')) {
      div.classList.remove("found")
    }
    if (_value instanceof String) {
      val = _value;
    } else {
      val = _value.target.value;
    }
    setSearchVal(val);
    searchInDoc(val);
  }

  function searchInDoc(_val) {
    let matches = [];
    for (const div of document.querySelectorAll('.search-zone span')) {
      if (div.textContent.toLowerCase().includes(_val.toLowerCase())) {
        matches.push(div);
        div.classList.toggle("found");
      }
    }

    setMatches(matches);
    setPrevSearchValue(_val);
    setCurrentMatchedIndex(0);
    return matches;
  }

  function searchInDocument(_val, _customMatches, direction) {
    let val = searchVal; //searchBoxRef.current.value;//.value();
    console.log(`Searching for '${_val}' or '${val}'`);
    let elements = matches;
    if (_val instanceof String) {
      val = _val;
    }
    if (_customMatches) {
      elements = _customMatches;
    }

    console.log(`Chose '${val}'`);

    setNeedToSearchFirst(false);
    if (val.length === 0) { return;}
    if (elements.length === 0) {
      console.log("No matches");
      return;
    }

    let div = elements[currentMatchedIndex];
    if (_customMatches) {
      div = elements[0];
      setCurrentMatchedIndex(0);
      div.scrollIntoView();
      return;
    }
    let newCurrentIndex = (currentMatchedIndex + 1) % (matches.length);
    if (direction < 0) {
      newCurrentIndex = currentMatchedIndex - 1;
      if (newCurrentIndex < 0) newCurrentIndex = matches.length - 1;
    }
    setCurrentMatchedIndex(newCurrentIndex);
    div.scrollIntoView();
  }

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    let pagesPinchZoomVal = {};
    for(let i = 0; i < numPages; i++)
      pagesPinchZoomVal[`${i}`] = false;
    // alert(JSON.stringify(pagesPinchZoomVal));
    setPagesPinchZoom(pagesPinchZoomVal);
    if (props.onDocumentLoadSuccess) props.onDocumentLoadSuccess(numPages);
    const urlParams = new URLSearchParams(document.location.search);
    const searchString = urlParams.get('search');
    if (searchString) {
      setSearchVal(searchString);
      setTimeout(() => {
        let _tempMatches = searchInDoc(searchString);
        while (needToSearchFirst) {
        }
        setTimeout(() => {searchInDocument(new String(searchString), _tempMatches)}, 2000);
        // checkSearchLength(searchString);
      }, 5000);
    }
  }

  function startPinchingOnPage(i, zoom, e) {
    let newVal = {... pagesPinchZoom};
    if (zoom.state.scale >= 0.99) {
      newVal[`${i}`] = true;
      setPagesPinchZoom(newVal);
      // alert(zoom.state.scale);
    } else {
      newVal[`${i}`] = false;
      setPagesPinchZoom(newVal);
      // alert(zoom.state.scale);
    }
    // alert(`${i} - ${zoom.state.scale} - ${Object.keys(e)}`);
  }

  function fullScreen(_value) {
    let newVal = !fullscreenMode;
    // alert(`${_value} - ${newVal}`);
    if (_value !== undefined) newVal = _value;
    if (_value !== null) newVal = _value;
    setFullscreenMode(newVal);
    if (newVal) {
      let elem = fullScreenEl.current;
      if (!elem) return;
      if (elem.requestFullScreen) {
        elem.requestFullScreen();
      } else if (elem.mozRequestFullScreen) {
        elem.mozRequestFullScreen();
      } else if (elem.webkitRequestFullScreen) {
        elem.webkitRequestFullScreen();
      }
    } else {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      }
      if (!props.hideControls) {
        document.exitFullscreen();
        setFullscreenMode(false);
      }
      // alert(3234);
    }
  }

  function download() {
    // alert(2131);
    let wnd = window.open(props.src, '_blank').focus();
  }

  return (
    <div ref={props.documentRef} style={{position: "relative"}} className={"w-full search-zone"}>
      <div ref={fullScreenEl} style={{position: "relative", maxHeight: "80vh", overflowY: "scroll", marginLeft: "auto", marginRight: "auto", marginTop: props.hideControls ? "0px" : "37px", maxWidth: "99vw", width: "100%"}}>
        <center>
          <Document className={props.hideControls ? '' : "scrolled"} error={"Ошибка загрузки файла"} style={{maxWidth: props.fullscreenMode ? "99vw" : "80vw", marginLeft: "auto", marginRight: "auto"}} loading={"Загрузка"} loadingPage={"Загрузка"} file={props.src} onLoadSuccess={onDocumentLoadSuccess}>
            { Array.apply(null, Array(numPages))
              .map((x, i)=>i+1)
              .map((page, i) => <div id={`page-${i+1}`} style={{marginLeft: document.body.clientWidth <= 540 ? "14px" : "auto", marginRight: "auto", maxWidth:  document.body.clientWidth <= 540 ? "80vw" : "80vw"}}>
                  { ((undefined === props.exactPage) || ((i === props.exactPage) && props.exactPage === i)) &&
                    <TransformWrapper
                      panning={{ disabled: !pagesPinchZoom[`${i}`], velocityDisabled: true }}
                      onZoom={(a,e) => {startPinchingOnPage(i, a, e)}}
                      disabled={document.body.clientWidth > 540}
                    >

                      <TransformComponent>
                          <Page loading={"Загрузка"} width={((fullscreenMode || props.fullscreenMode) ? document.body.clientWidth * 0.8 : document.body.clientWidth <= 540 ? document.body.clientWidth * 0.8 : document.body.clientWidth * 0.5) * magnification}  pageNumber={page}/>
                      </TransformComponent>
                    </TransformWrapper>
                  }
              </div>)
            }
            <br/>
            <br/>
            <br/>
            <br/>
          </Document>
        </center>
        <br />
      </div>
    </div>
  );
}
