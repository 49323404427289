import React from "react";
import Hider from "../hiders/Hider";
import CodeApproval from "../action_approvals/code_approval/CodeApproval";
import Api from "../../gateways/Api";
import logo from "../logo/logo.svg";
import User from "../../session/User";
import {QrReader} from "react-qr-reader";

interface LoginProps {
  api: Api,
  callback: (_e: any) => void
}

interface LoginState {
  enteringCode: boolean;
  scanningQr: boolean;
  disableLogin: boolean;
  loading: boolean;
  codeError: string | null;
  currentUser: User | null;
  checkingQR: boolean;
  error: string | null;
  showLogo: boolean;
  currentPosition: string | GeolocationPosition | null;
  autoCode: string | null;
}

export default class Login extends React.Component<LoginProps, LoginState> {
  checkingCode: boolean;
  checkingQR: boolean;
  mounted: boolean = false;
  private qrReaderRef: React.RefObject<typeof QrReader>;

  constructor(_props: LoginProps) {
    super(_props);

    let params = new URLSearchParams(document.location.search);
    let code = params.get("code") as string;

    this.state = {
      enteringCode: false,
      scanningQr: false,
      disableLogin: false,
      loading: false,
      codeError: null,
      currentUser: this.props.api.userSession.currentEntity,
      checkingQR: false,
      error: null,
      showLogo: true,
      currentPosition: null,
      autoCode: code
    };

    this.codeApprovalCallback = this.codeApprovalCallback.bind(this);
    this.activateStage1 = this.activateStage1.bind(this);
    this.activateStage2 = this.activateStage2.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.initialAction = this.initialAction.bind(this);
    this.codeApprovalCycle = this.codeApprovalCycle.bind(this);

    this.checkingCode = false;
    this.checkingQR = false;
    this.qrReaderRef = React.createRef<typeof QrReader>();
  }

  componentDidMount() {
    if (this.mounted) return;
    this.mounted = true;
    this.initialAction();

  }

  initialAction() {
    let me = this;
    if (this.props.api.userSession.currentEntity) {
      this.activateStage1();
      return;
    } else {
      let params = new URLSearchParams(document.location.search);
      let code = params.get("code") as string;
      if (code && code.length > 0) {
        this.codeApprovalCallback(code).then((_e) => {
          if (_e !== undefined) {
            me.activateStage2(JSON.stringify({value: "123"}));
          } else {
            setTimeout(me.initialAction, 3000);
          }
        }, () => {
        });
        // activateStage2
      }
    }
  }

  coordsToString(_position: string | GeolocationPosition | null) {
    if (_position === null) return null;
    if (typeof _position === 'string' || _position instanceof String) return _position;
    return `${_position.coords.latitude};${_position.coords.longitude};${_position.coords.altitude};${_position.coords.speed}`;
  }

  activateStage1() {
    let me = this;
    return new Promise((_resolve) => {
      let prevState = {...me.state};
      prevState.enteringCode = false;
      prevState.scanningQr = true;
      me.setState(prevState, () => {
        setTimeout(() => {
          navigator.geolocation.getCurrentPosition(function(_position: GeolocationPosition) {
            let prevState = {...me.state};
            prevState.currentPosition = _position;
            me.setState(prevState, () => {
              _resolve(true);
            });
          }, (e) => {
            let prevState = {...me.state};
            prevState.currentPosition = e.message;
            me.setState(prevState, () => {
              _resolve(false);
            });
          });
        }, 700);
      });
    })
  }

  activateStage2(_res:any) {
    let me = this;
    if (me.checkingQR) return true;
    console.log(_res);
    if (_res) {
      let obj = {} as any;
      try {
        obj = JSON.parse(_res);
      } catch (e) {
        let prevState = {...me.state};
        prevState.error = "Текущий код не предназначен для системы";
        me.setState(prevState);
        return;
      }
      me.checkingQR = true;
      if (obj.value) {
        console.log(obj.value);
      }
      setTimeout((e) => {
        let prevState = {...me.state};
        prevState.checkingQR = true;
        prevState.error = null;
        me.setState(prevState, () => {
          me.props.api.getUrl(`/conferences/${obj.conference_id}/check_qr`, {value: obj.value, coordinates: me.coordsToString(me.state.currentPosition)}).then((_res) => {
            let prevState = {...me.state};
            if (_res.error) {
              prevState.checkingQR = false;
              prevState.error = _res.error;
              me.checkingQR = false;
            } else {
              let prevState = {...me.state};
              prevState.enteringCode = false;
              prevState.scanningQr = false;
              prevState.disableLogin = true;
              me.setState(prevState, () => {
                // if (me.qrReaderRef.current) me.qrReaderRef.current.stopCamera();
                me.props.callback(_res);
                document.location.reload();
              });
            }
            me.setState(prevState);
          });
        });
      }, 0);
    }
    return true;
  }

  handleChange(_isOpen: boolean) {
    let prevState = {...this.state};
    prevState.enteringCode = true;
    this.setState(prevState);
  }

  codeApprovalCallback(_data: string) {
    let me = this;
    return new Promise((_resolve) => {
      if (_data.length === 6) {
        let prevState = {...me.state};
        prevState.loading = true;
        me.setState(prevState, () => {
          me.props.api.getUrl("/users/check_code", {secret_code: _data}).then((e) => {
            if (e.error) {
              let prevState = {...me.state};
              prevState.loading = false;
              prevState.codeError = e.error;

              me.setState(prevState, () => {
                _resolve(false);
              });
              return
            }
            let prevState = {...me.state};
            me.props.api.userSession.jwtToken = e.token;
            prevState.currentUser = me.props.api.userSession.currentEntity;
            me.setState(prevState, () => {
              me.activateStage1().then((_e) => {
                _resolve(_e);
              });
            });
          }, (err) => {
            let prevState = {...me.state};
            prevState.loading = false;
            prevState.codeError = "Ошибка связи";
            me.setState(prevState, () => {
              _resolve(undefined);
            });
            return
          })
        });
      }
    });
  }

  codeApprovalCycle(_code: string) {
    let me = this;
    me.codeApprovalCallback(_code).then((_res) => {
      if (_res === undefined) {
        setTimeout(() => {
          me.codeApprovalCycle(_code);
        }, 4000);
      }
    })
  }

  render() {
    let me = this;
    return <div style={me.state.disableLogin ? {display: "none"} : {} } className={"flex flex-column justify-start items-center content-center"}>
      {!me.state.disableLogin &&
          <Hider onChange={me.handleChange} headerClassName={"flex flex-row justify-center w-100"} isOpen={false}
                 showTitle={<div className={"mt-5"}>
                   { !me.state.autoCode ?
                     <button className={"registration-button mt-15"}>РЕГИСТРАЦИЯ</button>
                     :
                     <p>Загрузка</p>
                   }
                   </div>}>
              <>
                {/*<p>{me.coordsToString(me.state.currentPosition)}</p>*/}
                {me.state.enteringCode && !me.state.currentUser &&
                    <div className={"flex flex-column items-center"}>
                        <p className={"mb-3 mt-15"} style={{width: "60%", marginLeft: "auto", "marginRight": "auto"}}>Пожалуйста, введите Ваш уникальный код делегата Конференции</p>

                        <CodeApproval boxStyle={{opacity: me.state.loading ? 0.5 : 1, height: "64px", width: "42px", border: "none"}}
                                  callback={me.codeApprovalCycle}
                                      length={6}/>
                      {me.state.codeError &&
                        <p className={"mt-3"} style={{fontSize: "var(--font-size-4)", color: "var(--red)"}}>{me.state.codeError}</p>
                      }
                    </div>
                }
                {
                  me.state.scanningQr && me.state.currentUser &&
                    <div className={"flex flex-column mt-5"}>
                        <p>Добро пожаловать!</p>
                        <p>Для получения Ваших электронных бюллетеней и доступа к материалам Конференции, пожалуйста,
                            отсканируйте QR-код на экране при входе в зал проведения Конференции</p>
                        <div className={"v-spacer-3"}></div>
                    </div>
                }
                {
                  me.state.checkingQR && me.state.currentUser &&
                    <p className={""} style={{fontSize: "var(--font-size-4)"}}>Проверка кода</p>
                }
                {
                  me.state.error &&
                    <p className={"mt-1 mb-1"} style={{color: "var(--red)", fontSize: "var(--font-size-4)"}}>{me.state.error}</p>
                }
                {
                  me.state.scanningQr && me.state.currentUser &&
                    // <QrScanContainer key={`${me.state.scanningQr ? "1" : "2"}`} onResult={me.activateStage2} qrScanStyle={{
                    //   overflow: "hidden",
                    //   width: "90%",
                    //   minHeight: "400px",
                    //   borderRadius: "10px"
                    // }} scanImmediately={true}/>
                    <QrReader
                      scanDelay={500}
                      containerStyle={{width: "100%", height: "100%", borderRadius: "10px"}}
                      onResult={me.activateStage2}
                      constraints={{ facingMode:'environment'}}
                    />
                }
                {/*{*/}
                {/*  me.state.scanningQr &&*/}
                {/*    <p style={{opacity: 0.4}} onClick={me.activateStage2}>Скан</p>*/}
                {/*}*/}
              </>

          </Hider>
      }

      {!me.state.enteringCode &&
          <div style={{position: "absolute", bottom: "45px"}} className={"w-100 flex flex-row justify-center mt-15"}>
              <img style={{width: "80px", marginLeft: "auto", marginRight: "auto"}} src={logo}/>
          </div>
      }
    </div>;
  }
}