import React from "react";
import "./circle-spinner.css";

interface CircleSpinnerProps {
  circleColor?: string;
  dotsColor?: string;
  scale?: number
}

export default class CircleSpinner extends React.Component<CircleSpinnerProps, any> {
  constructor(_props: CircleSpinnerProps) {
    super(_props);
  }

  render() {
    let me = this;
    let scale = me.props.scale ? me.props.scale : 1;
    return <div className="circle-loader">
          <svg width={69 * scale} height={89 * scale} viewBox="0 0 79 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="39.5" cy="44.5" r="34" stroke={me.props.circleColor ? me.props.circleColor : "white"} strokeWidth="3"/>
            <circle cx="9" cy="59" r="7.5" fill="#1B196C" stroke={me.props.dotsColor ? me.props.dotsColor : "white"} strokeWidth="3"/>
            <circle cx="39" cy="9" r="7.5" fill="#1B196C" stroke={me.props.dotsColor ? me.props.dotsColor : "white"} strokeWidth="3"/>
            <circle cx="70" cy="59" r="7.5" fill="#1B196C" stroke={me.props.dotsColor ? me.props.dotsColor : "white"} strokeWidth="3"/>
          </svg>
        </div>
  }
}
