import React from "react";
import MenuComponent from "../../menus/MenuComponent";
import ModalDialog from "../../modal_dialog/ModalDialog";
import VotingPart from "../../voting_part/VotingPart";
import Header from "../../header/Header";

interface PanelState {
  shownVoting: string | null,
  doneVotings: string[];
  useQR: boolean;
}

export class Panel extends React.Component<any, PanelState> {
  history: MenuComponent[];
  private loaded: boolean;

  constructor(_props: any) {
    super(_props);

    let rawDoneVotings = localStorage.getItem("shown_votings") as string | null;
    let rawDoneVotingIds = [] as any[];
    if (rawDoneVotings) {
      // try {
      //   rawDoneVotingIds = JSON.parse(rawDoneVotings);
        rawDoneVotingIds = rawDoneVotings.split(",");
      // } catch (_e) {
      //   localStorage.removeItem("shown_votings");
      // }
    }
    let alreadyDoneVotings = rawDoneVotingIds; //rawDoneVotings ? rawDoneVotings.split(",")  : [];
    // alert(alreadyDoneVotings);
    // alert(alreadyDoneVotings?.length);
    this.state = {
      shownVoting: null,
      doneVotings: alreadyDoneVotings ? alreadyDoneVotings : [],
      useQR: false
    };

    this.loaded = false;
    this.history = [] as MenuComponent[];
    this.stopVoting = this.stopVoting.bind(this);
  }

  componentDidMount() {
    if (this.loaded) return;
    this.loaded = true;
    let me = this;
    setInterval(() => {
      me.checkVoting();
    }, 5000);
  }

  stopVoting(_votingId: string) {
    let prevState = {...this.state};
    prevState.doneVotings.push(_votingId);
    prevState.shownVoting = null;
    // alert(JSON.stringify(_votingId));
    let _votings = prevState.doneVotings.join(",");
    localStorage.setItem("shown_voting", _votingId);
    // localStorage.setItem("shown_votings", JSON.stringify(prevState.doneVotings));
    localStorage.setItem("shown_votings", _votings);
    this.setState(prevState);
  }

  checkVoting() {
    let me = this;
    this.props.api.getUrl(`/conferences/${me.props.conferenceId}/check_voting`, {}).then((_result: any) => {
      // console.log(_result);
      if ((!_result.id) || (_result.id.length === 0)) {
        let prevState = {...me.state};
        prevState.shownVoting = null;
        me.setState(prevState);
        return;
      }
      let _res = _result.id[0];

      // console.log(me.state.doneVotings);
      // console.log(_res);
      if (_res.id && (me.state.doneVotings.indexOf(`${_res.id}`) === -1)  && (me.state.doneVotings.indexOf(_res.id) === -1)) {
        // alert(`'${_res.id}' -- ${me.state.shownVoting}-${me.state.doneVotings}`)
        let prevState = {...me.state};
        prevState.shownVoting = _res.id;
        prevState.useQR = !!_res.use_qr;
        me.setState(prevState);
        return;
      }

      // if (`${_res.id}` !== `${me.state.shownVoting}`) {
      //   let prevState = {...me.state};
      //   prevState.shownVoting = _res.id;
      //   me.setState(prevState);
      // }

    });
  }

  render() {
    let me = this;
    return <div className={"card mt-6 "} style={{padding: 0, color: "#303030"}} >
          <div className={"relative"}>
            <MenuComponent getFromUrl={`/conferences/${me.props.conferenceId}/base?layout=1`}
                         api={me.props.api}/>
          </div>
      {
        me.state.shownVoting &&
          ((me.state.doneVotings.indexOf(me.state.shownVoting+"") === -1) &&
            me.state.doneVotings.indexOf(me.state.shownVoting) === -1) &&
          <ModalDialog style={{minHeight: "90vh"}}
                       isOpen={true}
                       closeOnBackdropClick={false} >
              {/*<iframe key={`${me.state.shownVoting}`} style={{border: "none", height: "90vh", width: "90vw"}} src={me.state.shownVoting} />*/}
              <Header/>
              <div className={"v-spacer-1"} />
              <VotingPart style={{minHeight: "320px",
                                  background: "var(--gray_100)"}}
                          votingId={me.state.shownVoting}
                          acceptingBulletinsUrl={`/conferences/${me.props.conferenceId}/get_my_bulletins`}
                          api={me.props.api}
                          useQR={me.state.useQR}
                          close={me.stopVoting} />
          </ModalDialog>
      }
    </div>;
  }
}