import { QrReader } from 'react-qr-reader';
import React from "react";

const previewStyle = {
  minHeight: "250px",
  width: "100%",
};

interface QrScanContainerProps {
}

interface QrScanContainerState {
  scanQR: boolean
}

export default class QrScanContainer extends React.Component<QrScanContainerProps, QrScanContainerState> {
  constructor(_props: QrScanContainerProps) {
    super(_props);

    this.state = {
      scanQR: false
    }
    this.startQR = this.startQR.bind(this);
    this.stopQR = this.stopQR.bind(this);
    this.onResult = this.onResult.bind(this);
  }

  startQR() {
    let prevState = {...this.state};
    prevState.scanQR = true;
    this.setState(prevState);
  }

  stopQR() {
    let prevState = {...this.state};
    prevState.scanQR = false;
    this.setState(prevState);
  }

  onResult(_e: any, _err: any) {
    let me = this;
    if (_e && me.state.scanQR) {
      // this.stopQR();
      // alert(e);
      console.log(_e);
    }
  }

  render() {
    let me = this;

    return <div key={`${me.state.scanQR}`}>
      <button onClick={me.startQR}>Отсканировать QR</button>

      { me.state.scanQR &&
          <div className={"flex flex-column justify-center items-center content-center"}>
            <QrReader
                containerStyle={previewStyle}
                onResult={me.onResult}
                constraints={{ facingMode:'environment'}}
            />

          </div>
      }
    </div>;
  }
}

