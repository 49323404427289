import React from "react";
import RICIBs from 'react-individual-character-input-boxes';

interface codeApprovalProps {
  callback: (_e:any) => void,
  length: number,
  boxStyle?: any,
  className?: string
}

export default class CodeApproval extends React.Component<codeApprovalProps, any> {

  constructor(_props: codeApprovalProps) {
    super(_props);

    this.handleOutput = this.handleOutput.bind(this);
  }

  componentDidMount() {
    let query = "input.cool-code-approval-input";
    let elements = document.querySelectorAll(query);
    elements.forEach((input:any) => {
      input.type = "tel";
    });
    let el = document.querySelector(query) as HTMLInputElement;
    if (el) el.focus();
  }

  handleOutput(_res: any) {
    this.props.callback(_res);
    console.log(_res);
  }

  render() {
    let me = this;
    let boxStyle = [];
    for(let i = 0; i < me.props.length; i++ ) {
      boxStyle.push({className: `cool-code-approval-input ${me.props.className}`, type: "tel", style: me.props.boxStyle})
    }


    return <div className={"cool-code-approval"}>
      <RICIBs
        amount={me.props.length}
        handleOutputString={this.handleOutput}
        inputProps={boxStyle}
        inputRegExp={/^[0-9]$/}
      />
    </div>
  }
}