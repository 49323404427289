import React from "react";
import "./fullscreen-loader.scss";


interface LoaderProps {
  loading: boolean,
  timeout?: number,
  greeting?: JSX.Element,
  spinner?: JSX.Element,
  style?: any
}

export default class FullscreenLoader extends React.Component<LoaderProps, any> {
  constructor(_props: LoaderProps) {
    super(_props);
  }

  render() {
    let me = this;

    if (!me.props.loading) {
      return <></>;
    }

    return (<div className={"loader"} style={me.props.style}>
      {me.props.greeting}
      {me.props.spinner}
    </div>);
  }
}